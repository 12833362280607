import React from "react";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import {
  getLifestyleImages,
  GetLifestyleImagesLeft,
  GetLifestyleImagesRight,
} from "utils/getLifestyleImages";
import { unescape } from "lodash";

const LifestyleImageAndVideo = ({ image, video }) => {
  // const lifestyleImages = getLifestyleImages();
  // const leftLifestyleImage = lifestyleImages && lifestyleImages[0];
  // const rightLifestyleImage = lifestyleImages && lifestyleImages[1];
  //   console.log("Ljubica Details: Images");
  //   console.log(leftLifestyleImage);

  const images = unescape(image).replace(
    /<img class="pagebuilder-mobile-only[^>]*>/g,
    ""
  );

  return (
    <MDBRow className="mb-5 w-100 lifestyle-row ml-0 mr-0 pl-0 pr-0">
      {images ? (
        <div
          className="lifestyle-img-wrapper"
          dangerouslySetInnerHTML={{
            __html: images,
          }}
        />
      ) : null
      // <>
      //   <MDBCol size="6" lg="6" md="6" sm="6" className="vplayerColLeft">
      //     <MDBBox className="vplayer-left" tag="div">
      //       <div className="lifestyle-img-wrapper">
      //         <GetLifestyleImagesLeft />
      //       </div>
      //     </MDBBox>
      //   </MDBCol>
      //   <MDBCol size="6" lg="6" md="6" sm="6" className="vplayerColRight">
      //     <MDBBox className="vplayer-right" tag="div">
      //       <div className="lifestyle-img-wrapper">
      //         <GetLifestyleImagesRight />
      //       </div>
      //     </MDBBox>
      //   </MDBCol>
      // </>
      }
    </MDBRow>
  );
};

export default LifestyleImageAndVideo;
